/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 50px 40px;
  background-color: #202124;
  color: #ccc;
  flex: 1;
  font-size: 1.06em;
`;

const Emoji = styled.span`
  width: 35px;
`;

const Text = styled.span`
  line-height: 1.4em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const App = () => {
  return (
    <Container>
      <Row>I'm Alex, I do full-stack contracting</Row>
      <br />
      <Row>
        <Emoji role="img" aria-label="Rocket emoji">
          🚀
        </Emoji>
        <Text>Typescript, React, GraphQL, WebGL, d3, Rust</Text>
      </Row>
      <Row>
        <Emoji role="img" aria-label="Email emoji">
          ✉️
        </Emoji>
        <Text>Drop me an email at alex @ domain</Text>
      </Row>
    </Container>
  );
};

export default App;
